/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useMemo } from "react";
import DataListInput from "react-datalist-input";
import { useDispatch } from 'react-redux';
import cn from "classnames";
import styles from "./CreateCollection.module.sass";
import Icon from "../../components/Icon";
import TextInput from "../../components/TextInput";
import Modal from "../../components/Modal";
import Preview from "./Preview";
import Collections from "./Collections";
import FolowSteps from "./FolowSteps";
import { connect } from "react-redux";
import { authSet } from "../../store/actions/auth.actions";
import {Rings} from  'react-loader-spinner'
import {NFT_ADDRESS, ABI, OWNER_ADDRESS, NFT_STORAGE_API_TOKEN} from "../../ABI/Pease2Ukraine/config.js";
import Web3Init from "../../components/InitWeb3";
import { NFTStorage } from "nft.storage"
import myToast from "../../components/Toast";
import convertURL from "../../components/convertURL";
import axios from "axios";


// Initialize the NFTStorage client
const storage = new NFTStorage({ token: NFT_STORAGE_API_TOKEN });


const Upload = (props) => {

  const [imgUpload, setImgUpload] = useState(false);
  const [load, setLoad] = useState(false);

  const [visibleModal, setVisibleModal] = useState(false);

  const [visiblePreview, setVisiblePreview] = useState(false);

  const [visibleCreateBtn, setVisibleCreateBtn] = useState(false);

  const [selectedImage, setSelectedImage] = useState();
  const [itemName, setItemName] = useState('');
  const [itemDesc, setItemDesc] = useState('');
  const [itemPrice, setItemPrice] = useState(0.0);
  const [itemCollection, setItemCollection] = useState('');
  const [previewImage, setPreviewImage] = useState();
  const [imgUploadState, setImgUploadState] = useState(false);
  const [hashValue, setHashValue] = useState('');
  const [collectionItems, setCollectionItems] = useState([]);
  const [tokenPrice, setTokenPrice] = useState(0.0);
  const [balance, setBalance] = useState(0);
  const dispatch = useDispatch();
  let [web3, setWeb3] = useState({});
  const [address, setAddress] = useState('');

  //======= Init Getting Data =========
  useEffect(async () => {
    // toast("Wow so easy!");
    
    setLoad(true);
    const web3 = await Web3Init();
    const networkId = await web3.eth.net.getId();
    const p2uNFTFactory = new web3.eth.Contract(
      ABI,
      NFT_ADDRESS,
    );
    const prices =  await p2uNFTFactory.methods.getAllPrices().call();
    const _collections = await p2uNFTFactory.methods.getAllCollections().call();
    const collectionItems = [];
    for (let i = 0; i < _collections.length; i++) {
      let jsonData = await axios.get(convertURL(_collections[i]));
      jsonData = jsonData.data;
      var itemData = {};
      itemData.id = i;
      itemData.price = (i < prices.length) ? prices[i] : 0.0;
      itemData.image = convertURL(jsonData.image);
      itemData.name = jsonData.name;
      itemData.description = jsonData.description;
      
      collectionItems.push(itemData);
    }
    setCollectionItems(collectionItems);
    setLoad(false);

    
    if (window.ethereum != null ) {
      // if (window.ethereum != null && Object.keys(props.user).length) {
      await window.ethereum.request({ method: 'eth_requestAccounts' }).then(async (data) => {
        setAddress(data[0]);
        const web3 = await Web3Init();
        const balance = await web3.eth.getBalance(data[0]);
        setBalance(web3.utils.fromWei(balance.toString(), "ether"));
      })
    }
    else {
      setAddress('');
    }
  }, [])

  //====== Image Change ======  
  async function imageChange(e) {
    if (e.target.files && e.target.files.length > 0) {
      setImgUpload(true);

      //=== Load Image Cache ===
      const reader = new window.FileReader();
      reader.readAsArrayBuffer(e.target.files[0]);
      reader.onloadend = () => {
        // setSelectedImage(Buffer(reader.result));
        setPreviewImage(URL.createObjectURL(e.target.files[0]));
      }

      //== add ==
      const file = e.target.files[0];
      setSelectedImage(file);
      setVisibleCreateBtn(true)
      //====================  
      setImgUpload(false);

    }
  }

 
  //===== Change Item Name ========
  const changeItemName = (e) => {
    setItemName(e.target.value);
  }

  const removeAll = () => {
    setPreviewImage();
    setSelectedImage();
    setItemName();
    setItemDesc();
    setItemPrice(0.0);
    setVisibleCreateBtn(false);
  }

  //======== UPLOAD IMAGE & METADATA JSON ==========
  const createMetadata = async (e) => {
    if(itemName === '' || itemDesc === '' || itemPrice < 0) {
      myToast("Please fill out  infomations to create collection", 'error'); return;
    };

    setVisibleModal(true);

    setImgUploadState(false);
    const metadata = await storage.store({
      name: itemName,
      description: itemDesc,
      price: itemPrice,
      image: new File([selectedImage], `${itemName}.jpg`, { type: 'image/jpg' }),
      attributes: {
        NFTname: "PEACE2UKRAINE"
      }
    })


    setHashValue(metadata.url);
    setImgUploadState(true);

    e.preventDefault();
  }
  return (
    <>
      <div className={cn("section", styles.section)}>
        <div className={cn("container", styles.container)}>
          {
            (load) ? <div className={styles.loading}> <Rings color="#ffd166" height={80} width={80} /> LOADING...</div> :
            <div style={{width: "100%", display: "flex", flexDirection: "column"}}>
              <Collections items = {collectionItems} />
              <div className={styles.setVal}>
                <div className={styles.wrapper}>
                
                  <div className={styles.head}>
                    <div className={cn("h2", styles.title)}>
                      Create Collection
                    </div>
                  </div>
                  <form className={styles.form} action="">
                    <div className={styles.list}>
                      <div className={styles.item}>
                        <div className={styles.category}>Upload Collection Image</div>
                        <div className={styles.note}>
                          Drag or choose collection image to upload
                        </div>
                        <div className={styles.file}>
                          {
                          (imgUpload) ? 
                            <div>
                              <Rings color="#ffd166" height={80} width={80}  ariaLabel='Uploading'/>
                              Uploading...  
                            </div> :
                            <div>
                              <input onChange={imageChange} className={styles.load} type="file" />
                              <div className={styles.icon}>
                                <Icon name="upload-file" size="24" />
                              </div>
                              <div className={styles.format}>
                                PNG, GIF, WEBP, MP4 or MP3. Max 1Gb.
                              </div>
                              {
                                previewImage && (
                                  <img src={(previewImage)} width="600px" height="300px" className={styles.imgFit} />
                                )
                              }
                            </div>
                          }
                        
                        </div>
                      </div>
                      <div className={styles.item}>
                        <div className={styles.category}>Collection Details</div>
                        <div className={styles.fieldset}>
                          <TextInput
                            className={styles.field}
                            label="Collection Name"
                            name="name"
                            type="text"
                            placeholder='e. g. Art Collection"'
                            onChange={changeItemName}
                            required
                          />
                          <TextInput
                            className={styles.field}
                            label="Description"
                            name="Description"
                            type="text"
                            placeholder="e. g. “Art Collection for photo gallery”"
                            value={itemDesc}
                            onChange={(e) => setItemDesc(e.target.value)}
                            required
                          />
                          <TextInput
                            className={styles.field}
                            label="Price for items of this Collection"
                            name="Price"
                            type="number"
                            placeholder="e. g. “100 MATIC”"
                            value={itemPrice}
                            onChange={(e) => setItemPrice(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    
                    <div className={styles.foot}>
                      <button
                        className={cn("button-stroke tablet-show", styles.button)}
                        onClick={() => setVisiblePreview(true)}
                        type="button"
                      >
                        Preview
                      </button>
                      <button
                        className={cn(`button ${visibleCreateBtn ? '' : "disabled"}`, styles.button)}
                        onClick={createMetadata}
                        type="button"
                      >
                        <span>Create</span>
                        <Icon name="arrow-next" size="10" />
                      </button>
                    </div>
                  </form>
                </div>
                <Preview
                  className={cn(styles.preview, { [styles.active]: visiblePreview })}
                  onClose={() => setVisiblePreview(false)}
                  imageData={previewImage}
                  itemName={itemName}
                  removeAll={removeAll}
                  itemDesc={itemDesc}
                  itemPrice={itemPrice}
                  accountBalance = {balance}
                />
              </div>
            </div>
          }
        </div>
      </div>

      <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <FolowSteps
          className={styles.steps}
          uploadState={imgUploadState}
          hashValue={hashValue}
          itemName={itemName}
          itemDesc={itemDesc}
          itemPrice={itemPrice}
          itemCollection={itemCollection}
        />
      </Modal>
    </>
  );
};

const mapToStateProps = ({ auth }) => ({
  user: auth.user
});

const mapToDispatchProps = (dispatch) => ({
  authSet: (payload) => dispatch(authSet(payload))
})

export default connect(mapToStateProps, mapToDispatchProps)(Upload);
