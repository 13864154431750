import React,{ useRef, useEffect, useState } from "react";
import styles from "./Instruction.module.sass";
const Instruction = () => {

  
 

    
  
  return (
    <>
      <div className={styles.container}>
        <div className={styles.desc}>
            <p className={styles.textStrong}>
            Ukraine has been under attack since February 24, 2022
            </p>

            <p className={styles.text}>
              Welcome to Peace2Ukraine.<br/> We are a group of developers working in Ukraine and we have been displaced by the war. 
              We have had to flee our homes in the big cities to look for safer places to live inside Ukraine. 
              It is not easy, and we are having a difficult time staying safe and getting food and clothing for us and our families.<br/> 
              We started this NFT collection to help raise funds for people in Ukraine and to support us while other sources are running dry.<br/> 
              When you buy one of our NFT images dedicated to commemorating the beautiful people and places of Ukraine, you are helping us to survive as we stay in the country to help keep it strong. 
              You will always be able to remember that you helped us and will have a picture of a beautiful place in Ukraine or one of our Survival series images that were made by creative art students.  Thank you very much!

            </p>
        </div>
      </div>
    </>
  );
};

export default Instruction;
