import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
// import Home from "./screens/Home";
import Home from "./screens/Home01";
// import UploadVariants from "./screens/UploadVariants";
import UploadDetails from "./screens/UploadDetails1";
import Price from "./screens/Price";
import ConnectWallet from "./screens/ConnectWallet";
import Faq from "./screens/Faq";
// import Activity from "./screens/Activity";
import Search01 from "./screens/Search01";
// import Search02 from "./screens/Search02";
import Profile from "./screens/Profile";
import ProfileEdit from "./screens/ProfileEdit";
import Item from "./screens/Item1";
// import PageList from "./screens/PageList";
import MyItemView from "./screens/MyItemView";
import CreateCollection from "./screens/CreateCollection";
// import UserCollection from "./screens/Home/UserCollection";
// import MarketCollection from "./screens/Home/MarketCollection";
// import MultiDetails from "./screens/MultiDetails";
  

function App() {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          render={() => (
            <Page>
              <Home />
            </Page>
          )}
        />
        <Route
          exact
          path="/upload-details"
          render={() => (
            <Page>
              <UploadDetails />
            </Page>
          )}
        />
       
        <Route
          exact
          path="/connect-wallet"
          render={() => (
            <Page>
              <ConnectWallet />
            </Page>
          )}
        />
        <Route
          exact
          path="/createCollection"
          render={() => (
            <Page>
              <CreateCollection />
            </Page>
          )}
        />
        <Route
          exact
          path="/faq"
          render={() => (
            <Page>
              <Faq />
            </Page>
          )}
        />
        <Route
          exact
          path="/setPrice"
          render={() => (
            <Page>
              <Price />
            </Page>
          )}
        />
        {/* <Route
          exact
          path="/activity"
          render={() => (
            <Page>
              <Activity />
            </Page>
          )}
        /> */}
        <Route
          exact
          path="/search01"
          render={() => (
            <Page>
              <Search01 />
            </Page>
          )}
        />
        {/* <Route
          exact
          path="/marketCollect"
          render={() => (
            <Page>
              <MarketCollection />
            </Page>
          )}
        />
         */}
        <Route
          exact
          path="/profile"
          render={() => (
            <Page>
              <Profile />
            </Page>
          )}
        />
        <Route
          exact
          path="/profile-edit"
          render={() => (
            <Page>
              <ProfileEdit />
            </Page>
          )}
        />

        {/* <Route
          exact
          path="/userCollection"
          render={() => (
            <Page>
              <UserCollection />
            </Page>
          )}
        /> */}

        <Route
          exact
          path="/MyItemView"
          render={() => (
            <Page>
              <MyItemView />
            </Page>
          )}
        />
        <Route
          exact
          path="/ItemView/:tokenID"
          render={() => (
            <Page>
              <Item />
            </Page>
          )}
        />
        {/* <Route
          exact
          path="/pagelist"
          render={() => (
            <Page>
              <PageList />
            </Page>
          )}
        /> */}
      </Switch>
    </Router>
  );
}

export default App;
