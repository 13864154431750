import React, { useState } from "react";
import cn from "classnames";
import styles from "./FolowSteps.module.sass";
import Icon from "../../../components/Icon";
import Loader from "../../../components/Loader";
import LoaderCircle from "../../../components/LoaderCircle";
import Web3Init from "../../../components/InitWeb3";
import {NFT_ADDRESS, ABI, OWNER_ADDRESS, NFT_STORAGE_API_TOKEN} from "../../../ABI/Pease2Ukraine/config.js";
import myToast from "../../../components/Toast";

const FolowSteps = ({ className, uploadState, collectionID, tokenID, tokenPrice}) => {
  const [signState, setSignState] = useState(0);
  const [errorState, setErrorState] = useState(false);

  const purchaseNFT = async () => {
    
    setSignState(1);

    const web3 = await Web3Init();
    const networkId = await web3.eth.net.getId();
    const P2UNFTFactory = new web3.eth.Contract(
      ABI,
      NFT_ADDRESS
    )
    const accounts = await web3.eth.getAccounts();
    //===== Check is Owner of NFT CONTRACT ========
    if(accounts[0] === OWNER_ADDRESS) {
      myToast("Please connect other wallet to purchase this NFT", 'error'); 
      setSignState(2);
      return;
    }

    const p2uNFTFactory = new web3.eth.Contract(
      ABI,
      NFT_ADDRESS,
    )

    if (NFT_ADDRESS) {
      p2uNFTFactory.methods.purchaseToken(tokenID, collectionID)
      .send({ from: accounts[0], gas:  web3.utils.toWei((300000).toString(), "wei"), value: web3.utils.toWei((tokenPrice ).toString(), "ether") })
      .once("receipt", (receipt) => {
        myToast("Purchased successfully!", 'success'); 
        
        window.location.reload();
      })
      .catch(err => {
        if (err) {
          myToast("Purchase failed!", 'error'); 
          setSignState(2);
        }
      });
    }
  }
  
  return (
    <div className={cn(className, styles.steps)}>
      <div className={cn("h4", styles.title)}>Folow steps</div>
      <div className={styles.list}>
        {
          !signState &&
          <div className={styles.item}>
            <div className={styles.head}>
              <div className={styles.icon}>
                <Icon name="pencil" size="24" />
              </div>
              <div className={styles.details}>
                <div className={styles.info}>Sign sell order</div>
                <div className={styles.text}>
                  Sign sell order using your wallet
                </div>
              </div>
            </div>
            <button onClick={purchaseNFT} className={cn(`button`, styles.button)}>
              Start now
            </button>
          </div>
        }
        {
          signState == 1 &&
          <div className={styles.item}>
            <div className={styles.head}>
              <div className={styles.icon}>
                <LoaderCircle className={styles.loader} />
              </div>
              <div className={styles.details}>
                <div className={styles.info}>Sign order</div>
                <div className={styles.text}>
                  Sign order using your wallet
                </div>
              </div>
            </div>
            <button className={cn("button loading", styles.button)}>
              <Loader className={styles.loader} color="white" />
            </button>
          </div>
        }
        {
          signState == 2 &&
          <div className={cn(styles.item, styles.error)}>
            <div className={styles.head}>
              <div className={styles.icon}>
                <Icon name="pencil" size="24" />
              </div>
              <div className={styles.details}>
                <div className={styles.info}>Sign order</div>
                <div className={styles.text}>
                  Sign order using your wallet
                </div>
              </div>
            </div>
            <button className={cn("button error", styles.button)}>Failed</button>
          </div>
        }
      </div>
      {
        errorState &&
        <div className={styles.note}>
          Something went wrong, please{" "}
          <a href="/#" target="_blank" rel="noopener noreferrer">
            try again
          </a>
        </div>
      }
    </div>
    
  );
};

export default FolowSteps;
