import React, { useState } from "react";
import cn from "classnames";
import styles from "./FolowSteps.module.sass";
import Icon from "../../../components/Icon";
import Loader from "../../../components/Loader";
import LoaderCircle from "../../../components/LoaderCircle";
import Web3Init from "../../../components/InitWeb3";
import {NFT_ADDRESS, ABI, OWNER_ADDRESS} from "../../../ABI/Pease2Ukraine/config.js";
import myToast from "../../../components/Toast";

const FolowSteps = ({ className, newPrice, collectionID, closeModal}) => {
  const [signState, setSignState] = useState(0);
  const [errorState, setErrorState] = useState(false);

  const updatePrice = async () => {
    
    
    setSignState(1);

    const web3 = await Web3Init();
    const networkId = await web3.eth.net.getId();
    const P2UNFTFactory = new web3.eth.Contract(
      ABI,
      NFT_ADDRESS
    )
    const accounts = await web3.eth.getAccounts();
    
    //===== Check is Owner of NFT CONTRACT ========
    if(accounts[0] !== OWNER_ADDRESS) {
      myToast("Please connect owner's wallet of this NFT", 'error'); 
      setSignState(2);
      return;
    }

    const price = web3.utils.toWei("0", 'ether');
    let BN = web3.utils.BN;
    const fee = new BN(price);
    // PhotoNFTFactory.methods.createNewPhotoNFT(itemName, "CINDE", price, hashValue[0].hash, itemDesc)
    P2UNFTFactory.methods.setPrice(collectionID, web3.utils.toWei((newPrice).toString(),"ether"))
      .send({ from: accounts[0], fee: fee })
      .once("receipt", async (receipt) => {
        if(receipt !== undefined && receipt.blockHash) {
          setSignState(0);
          myToast("Price upldated successfully!", 'success'); 
          closeModal();
        } else {
          setSignState(2);
          myToast("Update failed!", 'error'); 
        }
      })
      .catch(err => {
        if (err) {
          setSignState(2);
          myToast("Update failed!", 'error'); 
        }
      });
  }
  return (
    <div className={cn(className, styles.steps)}>
      <div className={cn("h4", styles.title)}>Folow steps</div>
      <div className={styles.list}>
       
        {
          !signState &&
          <div className={styles.item}>
            <div className={styles.head}>
              <div className={styles.icon}>
                <Icon name="pencil" size="24" />
              </div>
              <div className={styles.details}>
                <div className={styles.info}>Sign Transaction</div>
                <div className={styles.text}>
                  Sign transaction using your wallet
                </div>
              </div>
            </div>
            <button onClick={updatePrice} className={cn(`button `, styles.button)}>
              Start now
            </button>
          </div>
        }
        {
          signState == 1 &&
          <div className={styles.item}>
            <div className={styles.head}>
              <div className={styles.icon}>
                <LoaderCircle className={styles.loader} />
              </div>
              <div className={styles.details}>
                <div className={styles.info}>Sign Transaction</div>
                <div className={styles.text}>
                  Sign Transaction using your wallet
                </div>
              </div>
            </div>
            <button className={cn("button loading", styles.button)}>
              <Loader className={styles.loader} color="white" />
            </button>
          </div>
        }
        {
          signState == 2 &&
          <div className={cn(styles.item, styles.error)}>
            <div className={styles.head}>
              <div className={styles.icon}>
                <Icon name="pencil" size="24" />
              </div>
              <div className={styles.details}>
                <div className={styles.info}>Sign Transaction</div>
                <div className={styles.text}>
                  Sign Transaction using your wallet
                </div>
              </div>
            </div>
            <button className={cn("button error", styles.button)}>Failed</button>
          </div>
        }
        {
          signState == 3 &&
          <div className={styles.item}>
            <div className={styles.head}>
              <div className={styles.icon}>
                <Icon name="bag" size="24" />
              </div>
              <div className={styles.details}>
                <div className={styles.info}>Sign lock order</div>
                <div className={styles.text}>
                  Sign lock order using your wallet
                </div>
              </div>
            </div>
            <button className={cn("button", styles.button)}>Start now</button>
          </div>
        }
      </div>
      {
        errorState &&
        <div className={styles.note}>
          Something went wrong, please{" "}
          <a href="/#" target="_blank" rel="noopener noreferrer">
            try again
          </a>
        </div>
      }
    </div>
  );
};

export default FolowSteps;
