import React,{ useRef, useEffect, useState } from "react";
import Web3Init from "../../../components/InitWeb3";
import {NFT_ADDRESS, ABI, OWNER_ADDRESS, IPFS_PROXY} from "../../../ABI/Pease2Ukraine/config.js";
import axios from "axios";
import styles from "./Map.module.sass";
import myToast from "../../../components/Toast";
import convertURL from "../../../components/convertURL";
import connectPolygon from "../../../components/connectPolygon";
import { Link } from "react-router-dom";
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
mapboxgl.accessToken = 'pk.eyJ1IjoiZ2VuaXVzZGV2MDgxMyIsImEiOiJjbDB1YTRoYWMwbHdqM2ZwN3Yyc3A5ZXl1In0.u4swTmlNojb8JCOMIQDvfw';
const Map = () => {

  
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [signState, setSignState] = useState(0);
  const [errorState, setErrorState] = useState(false);
  const [lng, setLng] = useState(30.5234);
  const [lat, setLat] = useState(50.4501);
  const [zoom, setZoom] = useState(5);
  const [totalNFT, setTotalNFT] = useState(0);
  const [currentNFT, setCurrentNFT] = useState(0);
  let [NFTJsons, setNFTJsons] = useState([]);

  const getMapNFTs = async () => {
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom: zoom
    });

    setSignState(1);
    NFTJsons = [];
    const web3 = await Web3Init();
    try {
      const networkId = await web3.eth.net.getId();
      console.log(networkId);
    } catch (error) {
      myToast("Please install metamask wallet", 'error'); 
      return;
    }

    try {
     
      // const deployedNetwork = Peace2UkraineConfig.networks[networkId.toString()];
      const p2uNFTFactory = new web3.eth.Contract(
        ABI,
        NFT_ADDRESS,
      )

      // Get Total NFT Amount
      const total = await p2uNFTFactory.methods._lastTokenID().call();
      console.log("Total Amount : ", total);
      setTotalNFT(total);

      const nfts = await p2uNFTFactory.methods.tokensOfOwner(OWNER_ADDRESS).call();
      console.log(nfts);
      setCurrentNFT(nfts.length);
           
     
      for (let i = 0; i < nfts.length; i++) {

        //------ GET JSON URL
        let tokenID = nfts[i];
        let tokenURI =  await p2uNFTFactory.methods.tokenURI(tokenID).call();
        tokenURI = convertURL(tokenURI);
  
  
        //------- Get JSON DATA
        let jsonData = await axios.get(tokenURI);
        let data = jsonData.data;
        if(data.collection !== 0 ) return;
        data.image = convertURL(`${data.image}`);
        NFTJsons.push(data);
   

        const el = document.createElement('div');
        el.className = 'marker';
        el.style.height = "50px";
        el.style.width = "50px";
        el.style.borderColor = "yellow";
        el.style.borderWidth = "2px";
        el.style.backgroundImage = `url(${data.image})`;
        el.style.backgroundSize = 'cover';
        el.style.borderRadius = '50%';
        el.style.cursor =  'pointer';
        
        new mapboxgl.Marker(el).setLngLat( [ data.attributes.position.longitude, data.attributes.position.latitude]).setPopup(
          new mapboxgl.Popup({ offset: 25 }) // add popups
          .setHTML(
          `<a href="/ItemView/${tokenID}"> <h3 style="color: black;" >${data.name}</h3><h4 style="color: black;">${data.description}</h4><img src="${data.image}" width="200px" height ="100px">` + 
          `<div class="buyBtn" BUY NFT </div></a>`
          )
        ).addTo(map.current);
          
      }
    } catch (error) {
      myToast("Some error occured while loading data.", 'error'); 
    }
    
  }

  useEffect(async () => {
    if (window.ethereum != null ) {
      const res = await connectPolygon();
      if(!res) {
        myToast("Please switch network to Polygon main network!", 'error'); 
        return;
      }
    }
    else {
    }
    getMapNFTs();
  }, []);




  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on('move', () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });
  });

    
  
  return (
    <>
      <div className={styles.relative}>
        <div className={styles.mapBox}>
          {
            ((totalNFT -  currentNFT) > 0) ? 
              <div className={styles.soldout}>
                {(currentNFT == 0) 
                ?<label className={styles.souldOuttxt}>All Sold Out!</label>
                :<label className={styles.souldOuttxt}>{(totalNFT -  currentNFT)} sold out / Total {totalNFT}</label>}
              </div>
              : <></>
          }
          
          <div className={styles.sidebar}>
            {/* Longitude: {lng} | Latitude: {lat} | Zoom: {zoom} */}
            Click on an image to see the NFT of the city
          </div>
          <div ref={mapContainer} className={styles.mapContainer}  />
        </div>
      </div>
        
        
    </>
  );
};

export default Map;
