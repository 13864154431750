import React, { useState } from "react";
import cn from "classnames";
import styles from "./Hero.module.sass";
import Dropdown from "../../../components/Dropdown";
import Icon from "../../../components/Icon";
import Item from "./Item";

const items = [
  {
    title: "General",
    icon: "home",
    items: [
      {
        header: "What is an NFT?!", 
        content: `NFT stands for “Non-Fungible Token” which means it is a one of a kind work with a unique identifying code that is linked to the blockchain, in a way similar to cryptocurrency. It therefore contains built-in authentication, which serves as proof of ownership so you can prove that YOU and you only are the owner of the original version of the image. You can proudly show your friends, family and colleagues that you helped to support Ukraine during this historically challenging period. <a href="https://www.forbes.com/advisor/investing/cryptocurrency/nft-non-fungible-token/" target="_blank" style="color: yellow;font-weight: 600;">This is a good article</a> that explains the ins and outs of NFTs.`,
      },
      {
        header: "Who made the art?",
        content: "The basic pictures in the Map Art series were taken from publically available sources with creative commons licences and then we minted them into NFTs, and added the coats of arms and the city names. Each one distills the spirit of each city into the image and is the perfect way to learn about the wonders of Ukraine while supporting the people under fire. The other series use images that capture the spirit of Ukraine and were made by our team members and friends."
      },
      {
        header: "Where does the money go?",
        content: `80% of the money from your NFT goes to the charity <a href="https://unitedhelpukraine.org" target="_blank" style="color: yellow;font-weight: 600;"> unitedhelpukraine.org </a> that focusses on: 
        <ul style="margin-left: 20px"> 
          <li > <strong>-</strong> Medical supplies: Individual First Aid Kits for service members of Ukrainian Armed Forces and Territorial Defense Forces; hospital equipment.</li>
          <li> <strong>-</strong> Personal Protective, Medical, and Survival Equipment</li>
          <li> <strong>-</strong> Humanitarian Aid: monetary grants and shipping of goods to internally displaced Ukrainians and refugees</li>
          <li> <strong>-</strong> Wounded Warrior: dedicated fundraisers for the Ukrainian wounded, including those who are being treated in American hospitals; support for their families</li>
          <li> <strong>-</strong> Raising Awareness: hosting rallies and cultural events to bring attention to Ukraine’s struggle for freedom and independence.</li> 
        </ul>
        </br>
        The other 20% goes to our team of developers who live in Ukraine to support them and their families during this unprecedented time in their lives`    
      },
      {
        header: "What is the value of these NFTs?",
        content: "We are setting the prices of our NFTs so that we can give 80% to a general charity that helps Ukraine and also keep 20% to help us and our families who live in Ukraine to get food and shelter in this very difficult and uncertain time. Many NFTs gain value on secondary markets when other people want to own part of this history. Our NFTs may gain value or not over time, but one thing to keep in mind is that whatever the value is in the future of these NFTs, the money you pay to buy it will go to help Ukraine in its fight against oppression and to defend democracy, so you are helping to make the world a better place no matter what happens."
      }
    ],
  }
];

const Hero = () => {
  const options = [];
  items.map((x) => options.push(x.title));

  const [direction, setDirection] = useState(options[0]);

  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.top}>
          <div className={styles.stage}>learn how to get started</div>
          <h1 className={cn("h2", styles.title)}>Frequently asked questions</h1>
          
          <Dropdown
            className={cn("mobile-show", styles.dropdown)}
            value={direction}
            setValue={setDirection}
            options={options}
          />
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.nav}>
              {items.map((x, index) => (
                <div
                  className={cn(styles.link, {
                    [styles.active]: x.title === direction,
                  })}
                  onClick={() => setDirection(x.title)}
                  key={index}
                >
                  <Icon name={x.icon} size="16" />
                  <span>{x.title}</span>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.col}>
            {items
              .find((x) => x.title === direction)
              .items.map((x, index) => (
                <Item className={styles.item} item={x} key={index} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
