import React from "react";
import cn from "classnames";
import styles from "./Preview.module.sass";
import Icon from "../../../components/Icon";
import { POLYGON_NET } from "../../../ABI/Pease2Ukraine/config";

const Preview = ({ className, onClose, imageData, itemName, removeAll, tokenPrice, accountBalance }) => {

  const removeInfo = () => {
    removeAll();
  }

  return (
    <div className={cn(className, styles.wrap)}>
      <div className={styles.inner}>
        <button className={styles.close} onClick={onClose}>
          <Icon name="close" size="14" />
        </button>
        <div className={styles.info}>Preview</div>
        <div className={styles.card}>
          <div className={styles.preview}>
            {imageData && (
              <img
                src={(imageData)}
                alt="Card"
              />
            )}
          </div>
          <div className={styles.link}>
            <div className={styles.body}>
              <div className={styles.line}>
                <div className={styles.title}>{itemName && itemName}</div>
                <div className={styles.price}>{tokenPrice} {POLYGON_NET.symbol}</div>
              </div>
              
            </div>
            <div className={styles.foot}>
              <div className={styles.status}>
                <p>
                  <Icon name="coin" size="20" />
                  Ready to create
                </p> 
                <span>{Number(accountBalance).toFixed(3)} {POLYGON_NET.symbol}</span>
              </div>
              <div className={styles.bid}>
                New NFT
                <span role="img" aria-label="fire">
                  🔥
                </span>
              </div>
            </div>
          </div>
        </div>
        <button className={styles.clear} onClick={removeInfo}>
          <Icon name="circle-close" size="24" />
          Clear all
        </button>
      </div>
    </div>
  );
};

export default Preview;
