/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./MyItem.module.sass";
import { Range, getTrackBackground } from "react-range";
import Icon from "../../components/Icon";
import Card from "./MyItemCard";
import Dropdown from "../../components/Dropdown";
import Web3Init from "../../components/InitWeb3";
import { connect } from "react-redux";
import { useDispatch } from 'react-redux';
import { getNFTDataAll } from "../../store/actions/bid_action";
import { useLocation } from "react-router-dom";
import {NFT_ADDRESS, ABI, OWNER_ADDRESS, IPFS_PROXY} from "../../ABI/Pease2Ukraine/config.js";
import axios from "axios";
import myToast from "../../components/Toast";
import convertURL from "../../components/convertURL";
import Loader from "../../components/Loader";


const dispatch = useDispatch;
// data

const navLinks = ["All items", "Art", "Game", "Photography", "Music", "Video"];

const dateOptions = ["Newest", "Oldest"];
const likesOptions = ["Most liked", "Least liked"];
const colorOptions = ["All colors", "Black", "Green", "Pink", "Purple"];
const creatorOptions = ["Verified only", "All", "Most liked"];

const MyItemView = () => {


  const [activeIndex, setActiveIndex] = useState(0);
  const [date, setDate] = useState(dateOptions[0]);
  const [likes, setLikes] = useState(likesOptions[0]);
  const [color, setColor] = useState(colorOptions[0]);
  const [creator, setCreator] = useState(creatorOptions[0]);
  const [filterItems, setFilterItems] = useState([]);
  const [items, setItems] = useState([]);
  const [search, setSearch] = useState("");
  const [address, setAddress] = useState("");
  const [values, setValues] = useState([5]);
  const [loading, setLoading] = useState(false);
  const [tokenPrice, setTokenPrice] = useState(0.0);
  const [collections, setCollections] = useState([]);
  const rsearch = useLocation().search;

  const collection = new URLSearchParams(rsearch).get("collection");

  const handleSubmit = (e) => {
    alert();
  };

   // Get Collections
   const getCollections = async () => {
    try {
      const web3 = await Web3Init();
      const networkId = await web3.eth.net.getId();

      try {
        const p2uNFTFactory = new web3.eth.Contract(
          ABI,
          NFT_ADDRESS,
        );
        
        // const price = await p2uNFTFactory.methods.price().call();
        // setTokenPrice(web3.utils.fromWei(price.toString(), "ether"));
        
        const _collections = await p2uNFTFactory.methods.getAllCollections().call();
    
        for (let i = 0; i < _collections.length; i++) {
          let jsonData = await axios.get(convertURL(_collections[i]));
          jsonData = jsonData.data;
          var itemData = {};
          itemData.id = i;
          itemData.image = convertURL(jsonData.image);
          itemData.name = jsonData.name;
          // itemData.price = (itemData.price === undefined) ? price : itemData.price;
          itemData.description = jsonData.description;
          collections.push(itemData);
        }
      } catch (error) {
        myToast("Some error occured while feteching data!", "error");
      }
    } catch (error) {
      myToast("Please install Metamask!", "error");
    }
  }

  const getMyItems = async() => {
    const web3 = await Web3Init();
    try {
      const networkId = await web3.eth.net.getId();
      if (window.ethereum != null ) {
        // if (window.ethereum != null && Object.keys(props.user).length) {
        await window.ethereum.request({ method: 'eth_requestAccounts' }).then(async (data) => {
          setAddress(data[0]);
          try {
            // const deployedNetwork = Peace2UkraineConfig.networks[networkId.toString()];
            const p2uNFTFactory = new web3.eth.Contract(
              ABI,
              NFT_ADDRESS,
            )

            // debugger;
            // const price = await p2uNFTFactory.methods.price().call();
            // setTokenPrice(web3.utils.fromWei(price.toString(), "ether"));

            const nfts = await p2uNFTFactory.methods.tokensOfOwner(data[0]).call();
            let nftJSONs = [];
            for (let i = 0; i < nfts.length; i++) {
              //------ GET JSON URL
              let tokenID = nfts[i];
              let tokenURI =  await p2uNFTFactory.methods.tokenURI(tokenID).call();
              tokenURI = convertURL(tokenURI);
        
        
              //------- Get JSON DATA
              let jsonData = await axios.get(tokenURI);
              let data = jsonData.data;
              data.id = i;
              data.image = convertURL(`${data.image}`);
              data.tokenID = tokenID;
              nftJSONs.push(data);
              setItems(nftJSONs);
            }
            // debugger
      
          } catch (error) {
            myToast("Some error occured while loading data.", 'error'); 
          }
        })
      }
      else {
        setAddress('');
        myToast("Please install metamask wallet", 'error'); 
        return;
      }
    } catch (error) {
      myToast("Please install metamask wallet", 'error'); 
      return;
    }
  }

  useEffect(async () => {
    setLoading(true);
    await getCollections();
    await getMyItems(); 
    setLoading(false);
  }, [])

  const STEP = 0.1;
  const MIN = 0.01;
  const MAX = 10;

  return (
      <div className={cn("section-pt80", styles.section)}>
        <div className={cn("container", styles.container)}>
          
          
            <div className={styles.row}>
              <div className={styles.wrapper}>
                <div className={styles.head}>
                  <div className={cn("h2", styles.title)}>
                    MY NFT ITEMS
                  </div>
                </div>
                <div className={styles.list}>
                  {items.map((x, index) => (
                    <Card className={styles.card} item={x} collections={collections} key={index} />
                  ))}
                </div>
                {
                  loading && 
                  <div className={styles.btns}>
                    <div style={{display: "flex", justifyContent : "center", alignItems: "center", width : "100%"}}><Loader ></Loader></div>
                  </div>
                }
              </div>
            </div>
          
          </div>
      </div>
  );
};
const mapToStateProps = ({ uploading }) => ({
  upload: uploading.upload
});

const mapToDispatchProps = (dispatch) => ({
  uploadBid: (payload) => dispatch(getNFTDataAll(payload))
})

export default connect(mapToStateProps, mapToDispatchProps)(MyItemView);
