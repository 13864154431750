import React from "react";
import MapView from "./Map";
import Instruction from "./Instruction";
import Valences from "./Valences";
import RoadMap from "./RoadMap";
import News from "./News";
import Videos from "./Videos";
import styles from "./Home.module.sass";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";
import Icon from "../../components/Icon";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink,faWallet } from '@fortawesome/free-solid-svg-icons'
const Home = () => {

 
  return (
    <>
      <div className={styles.container}>
        <div>
          <video className={styles.videoTag} autoPlay muted loop>
            <source src='./Explosions.mp4' type="video/mp4" />
          </video>
        </div>
      
        <div className = {styles.row}>
          <img src="./images/map.png" width="auto" className={styles.helpImage}></img>
          <RoadMap/>
          <img src="./help.jpg" width="auto" className={styles.helpImageMap}></img>
        </div>
        <div className={styles.row}>
          <Instruction className = {styles.instruction}/>
        </div>
        <div className = {styles.row}>
          <MapView className={styles.map} />     
          <Videos/>
        </div>
        <div className = {styles.row}>
          <Valences className = {styles.instruction}/>
        </div>
      </div>
      
    </>
  );
};

export default Home;
