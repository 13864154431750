/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import {NFT_ADDRESS, ABI, OWNER_ADDRESS, POLYGON_NET} from "../../ABI/Pease2Ukraine/config.js";
import Options from "./Options";
import Web3Init from "../../components/InitWeb3";
import { getNFTDataById } from "../../store/actions/bid_action";
import { connect } from "react-redux";
import axios from "axios";
import myToast from "../../components/Toast";
import convertURL from "../../components/convertURL";
import {Rings} from  'react-loader-spinner'
import FolowSteps from "./FolowSteps";
import Modal from "../../components/Modal";

import { 
  useLocation,
  useParams,
  BrowserRouter as Router,
  Route,
  Routes,
  Link, } from "react-router-dom";


const Item = () => {
  const [itemData, setItemData] = useState({});
  const [collectionData, setCollectionData] = useState({});
  const [loading, setLoading] = useState(false);
  const [tokenPrice, setTokenPrice] = useState(0.0);
  const { tokenID } = useParams();
  const [address, setAddress] = useState('');
  const [visibleModal, setVisibleModal] = useState(false);
  const [ownerAddress, setOwnerAddress] = useState('');

  /*****************************
   *  Init Get NFT
   */
  const getNFT = async (_tokenID) => {
    
    // setLoading(true);
    // Init Web3
    const web3 = await Web3Init();
    try {
      const networkId = await web3.eth.net.getId();
      
      const accounts = await web3.eth.getAccounts();
      setAddress(accounts[0]);
    } catch (error) {
      myToast("Please install metamask wallet", 'error');
      // setLoading(false);
    }
   
    // Get NFT Data
    try {
      const p2uNFTFactory = new web3.eth.Contract(
        ABI,
        NFT_ADDRESS,
      )

       

      // Get Token JSON URL
      let tokenURI =  await p2uNFTFactory.methods.tokenURI(tokenID).call();
      tokenURI = convertURL(tokenURI);

      // Get Json Data from URL
      let jsonData = await axios.get(tokenURI);
      let data = jsonData.data;
      data.image = convertURL(`${data.image}`);
      data.collection = data.collection === undefined ? 0 : data.collection
      data.attributes = data.attributes === undefined ? {} : data.attributes;     
      setItemData(data);
  

      // Get Collection Data
      let collectionURI =  await p2uNFTFactory.methods.getCollectionByIndex(data.collection).call();
      collectionURI = convertURL(collectionURI);

      // Get Json Data from URL
      let collData = await axios.get(collectionURI);
      let coldata = collData.data;
      coldata.image = convertURL(`${coldata.image}`);
      setCollectionData(coldata);

      // Get Token Price
      let price =  await p2uNFTFactory.methods.getPriceByCollectionID(data.collection).call();
      setTokenPrice(web3.utils.fromWei(price.toString(), "ether"));

       // Get Owner Address
       let owner =  await p2uNFTFactory.methods.ownerOf(tokenID).call();
       setOwnerAddress(owner);
       

    } catch (error) {
      myToast("Some error occured while loading data.", 'error'); 
      // setLoading(false);
    }
    
  }

  const buyFunc = async () => {
    setVisibleModal(true);
    
  }

  const favoriteFunc = async () => {
    // Init Web3
    const web3 = await Web3Init();
    const networkId = await web3.eth.net.getId();
    const p2uNFTFactory = new web3.eth.Contract(
      ABI,
      NFT_ADDRESS,
    )
    
    // Get Token JSON URL
    let tokenURI =  await p2uNFTFactory.methods.tokenURI(tokenID).call();

    // Get Json Data from URL
    let jsonData = await axios.get(tokenURI);
    let data = jsonData.data;
    setItemData(data);

     // Get Token Price
     let price =  await p2uNFTFactory.methods.price().call();
     setTokenPrice(price / Math.pow(10, 18));
  }
  
  useEffect(async () => {
    setLoading(true);
    if (window.ethereum != null ) {
      try {
         // if (window.ethereum != null && Object.keys(props.user).length) {
        await window.ethereum.request({ method: 'eth_requestAccounts' }).then(async (data) => {
          const web3 = await Web3Init();
          const balance = await web3.eth.getBalance(data[0]);
        })
      } catch (error) {
        myToast("Some error occured! \r\n Please check your connection!", 'error');
        return;
      }
     
    }
    else {
    }
    await  getNFT(tokenID*1);
    setLoading(false);

  },[]);

  return (
    <>
      <div className={cn("section", styles.section)} >
        <div className={cn("container", styles.container)}>
          {
            (loading) ? <div className={styles.loading}> <Rings color="#ffd166" height={80} width={80} /> LOADING...</div> : 
            <>
            <div className={styles.bg}>
              <div className={styles.preview}>
                <img
                  // srcSet="/images/content/item-pic@2x.jpg 2x"
                  // srcSet={`http://ipfs.io/ipfs/${i.ipfsHashOfPhoto}`}
                  src={`${itemData.image}`}
                  alt={`${itemData.name}`}
                  width = {300}
                  height = {530}
                />
              </div>
              
            </div>
            <div className={styles.details}>
              <h1 className={cn("h3", styles.title)}>{itemData.name}</h1>
              <div className={styles.cost}>
                <div className={cn("status-stroke-green", styles.price)}>
                  { tokenPrice } {POLYGON_NET.symbol}
                </div>
                <div className={cn("status-stroke-yellow", styles.price)}>
                  {collectionData.name}
                </div>
              </div>
              <div className={styles.info}>{itemData.description}</div>
              {(itemData.collection === 0) ?
              <div className={styles.detail}>
                <h3>Details:</h3>
                <h3>
                  <strong>Latitude: </strong> <label> {itemData.attributes === undefined ? "" : itemData.attributes.position.latitude}</label> &nbsp;&nbsp; <strong>Longitude: </strong> <label>{itemData.attributes === undefined ? "" :itemData.attributes.position.longitude}</label>
                </h3>
                <h3>
                  <strong>Source: </strong>
                  <label>{itemData.attributes === undefined ? "" : itemData.attributes.source}</label>
                </h3>

                <h3>
                  <strong>Mint At: </strong>
                  <label>{itemData.attributes === undefined ? "2022-4-15" : itemData.attributes.mintDate === undefined ? "2022-4-15" : itemData.attributes.mintDate}</label>
                </h3>
                
              </div> : <></>}
              <div>
                {
                  (address !== '' && address !== ownerAddress && ownerAddress === OWNER_ADDRESS) ?<Options className={styles.options} buyHandler = {buyFunc} favHandler = {favoriteFunc}/> : null
                }
                
              </div>
            </div>
          </>
          }
        </div>
      </div>

      <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <FolowSteps
          className={styles.steps}
          tokenID = {tokenID}
          collectionID = {itemData.collection}
          tokenPrice = { tokenPrice }
        />
      </Modal>
    </>
  );
};

const mapToStateProps = ({ uploading }) => ({
  upload: uploading.upload
});

const mapToDispatchProps = (dispatch) => ({
  uploadBid: (payload) => dispatch(getNFTDataById(payload))
})

export default connect(mapToStateProps, mapToDispatchProps)(Item);
