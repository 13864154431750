import React from "react";
import cn from "classnames";
import styles from "./Options.module.sass";
import Icon from "../../../components/Icon";

const Options = (props) => {

  return (
    <div className={cn(styles.options)}>
      <button className={cn("button-stroke-yellow", styles.button)} onClick = {()=>props.buyHandler()}>
        <Icon name="wallet" size="24" /> BUY
      </button>
      {/* <button
        className={cn("button-stroke-yellow", styles.button, styles.favorite)} onClick = {() => props.favHandler()}
      >
        <Icon name="heart-fill" size="24" /> FAVORITE
      </button>
       */}
    </div>
  );
};

export default Options;
