import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Card.module.sass";
import Icon from "../Icon";
import Modal from "../../components/Modal";
import Web3Init from "../InitWeb3";
import { POLYGON_NET } from "../../ABI/Pease2Ukraine/config";
// import Buy from "../Buy";

const Card = ({ className, item, collections, tokenPrice }) => {
  const [visible, setVisible] = useState(false);
  const [visibleModalBid, setVisibleModalBid] = useState(false);
  const [balance, setBalance] = useState(0);
  const [address, setAddress] = useState("");
  const [checkApprove, setCheckApprove] = useState(false);
  const [checkOwner, setCheckOwner] = useState(false);

  // useEffect(async ()=> {
    
  //   await window.ethereum.request({method: 'eth_requestAccounts'}).then(async(data) => {
  //     const web3 = await Web3Init();
  //     const balance = await web3.eth.getBalance(data[0]);
  //     setAddress(data[0]);
  //     setBalance(web3.utils.fromWei(balance.toString(), "ether"));
  //     if(itemData){
  //     if(itemData.ownerAddress.toUpperCase() === data[0].toUpperCase()){
  //         setCheckOwner(true);
  //     }
  //   }
  //     if(item.checkBid !== undefined){
  //       if(item.checkBid._id !== undefined){
  //       setCheckApprove(true);
  //     }
  //     }
  //   });
  // }, [item]);

  return (
    <div className={cn(styles.card, className)}>
      <div className={styles.preview}>
        <img srcSet={`${item.image} 2x`} src={item.image} alt="Card" />
        <div className={styles.control}>
          <div
            className={cn(
              { "status-green": item.collection === "green" },
              styles.category
            )}
          >
            {collections[item.collection].name}
          </div>
          <button
            className={cn(styles.favorite, { [styles.active]: visible })}
            onClick={() => setVisible(!visible)}
          >
            <Icon name="heart" size="20" />
          </button>
          
          
          <Link className={styles.link} to={`ItemView/${item.tokenID}`}>
            <button className={cn("button-small", styles.button)}
            onClick={()=>{
              setVisibleModalBid(true);
            }}
            >
              <span>VIEW DETAIL</span>
              <Icon name="scatter-up" size="16" />
            </button>
          </Link>
        </div>
      </div>
      
      <div className={styles.body}>
        <div className={styles.line}>
          <div className={styles.title}>{item.name}</div>
          <div className={styles.price}><strong style={{color: "white "}}>{(item.attributes === undefined) ? 0.0 : item.attributes.price } </strong> {POLYGON_NET.symbol}</div>
        </div>
        
      </div>
      <div className={styles.foot}>
        <div className={styles.status}>
          <Icon name="candlesticks-up" size="20" />
          <span>{item.description} </span>
        </div>
        
      </div>
      
    </div>
  );
};


export default Card;

