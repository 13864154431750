import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Search01.module.sass";
import Icon from "../../components/Icon";
import Dropdown from "../../components/Dropdown";
import Web3Init from "../../components/InitWeb3";
import { connect } from "react-redux";
import { useDispatch } from 'react-redux';
import { useLocation } from "react-router-dom";
import { getNFTDataAll } from "../../store/actions/bid_action";
import Card from "../../components/Card";
import { ABI, NFT_ADDRESS, OWNER_ADDRESS } from "../../ABI/Pease2Ukraine/config";
import myToast from "../../components/Toast";
import axios from "axios";
import convertURL from "../../components/convertURL";
import Loader from "../../components/Loader";

const dispatch = useDispatch;

// data

const navLinks = ["All items"];

const dateOptions = ["Newest", "Oldest"];
const likesOptions = ["Most liked", "Least liked"];
const colorOptions = ["All colors", "Black", "Green", "Pink", "Purple"];
const creatorOptions = ["Verified only", "All", "Most liked"];

const Search = () => {

  let [activeIndex, setActiveIndex] = useState(0);
  const [filterItems, setFilterItems] = useState([]);
  const [items, setItems] = useState([]);
  const [tokenIDs, setTokenIDs] = useState([]);
  const [search, setSearch] = useState("");
  const [tokenPrice, setTokenPrice] = useState(0.0);
  const [loading, setLoading] = useState(false);
  const [navLinks, setNavLinks] = useState([{id: -1, name: "All items"}]);
  const [collections, setCollections] = useState([]);
  const rsearch = useLocation().search;
  const collection = new URLSearchParams(rsearch).get("collection");
  const [totalNFT, setTotalNFT] = useState(0);
  const [currentNFT, setCurrentNFT] = useState(0);


  const handleSubmit = async(event) => {


    event.preventDefault();
    event.preventDefault();
    
    await filter();
    
  };

  const filterByCollection = async(index) => {
    activeIndex = index;
    setActiveIndex(index);

    await filter();
  }

  const filter =  async() => {
    setLoading(true);
    setFilterItems([]);
    let filtered = [];
    items.forEach(item => {
      if(activeIndex !== 0 && item.collection !== activeIndex - 1) return;
      if(item.name.toLowerCase().includes(search.toLowerCase())){
        filtered.push(item);
        setFilterItems(filtered);
      }
    });

    setSearch("");
    setLoading(false);

  }

  // Get Collections
  const getCollections = async () => {
    try {
      const web3 = await Web3Init();
      const networkId = await web3.eth.net.getId();

      try {
        const p2uNFTFactory = new web3.eth.Contract(
          ABI,
          NFT_ADDRESS,
        );
        // debugger
        // const price = await p2uNFTFactory.methods.price().call();
        // setTokenPrice(web3.utils.fromWei(price.toString(), "ether"));
        
        

        const _collections = await p2uNFTFactory.methods.getAllCollections().call();
        const collectionItems = [{id: -1, name: "All items"}];
        for (let i = 0; i < _collections.length; i++) {
          let jsonData = await axios.get(convertURL(_collections[i]));
          jsonData = jsonData.data;
          var itemData = {};
          itemData.id = i;
          itemData.image = convertURL(jsonData.image);
          itemData.name = jsonData.name;
          // itemData.price = (itemData.price === undefined) ? price : itemData.price;
          itemData.description = jsonData.description;
          collectionItems.push(itemData);
          setNavLinks(collectionItems);
          collections.push(itemData);
        }
      } catch (error) {
        myToast("Some error occured while feteching data!", "error");
      }
    } catch (error) {
      myToast("Please install Metamask!", "error");
    }
   
   

    
  }

  const getAllNFTs = async () => {
  
    const web3 = await Web3Init();
    try {
      const networkId = await web3.eth.net.getId();
    } catch (error) {
      myToast("Please install metamask wallet", 'error'); 
      return;
    }

    try {
      let allNFT = [];
      // const deployedNetwork = Peace2UkraineConfig.networks[networkId.toString()];
      const p2uNFTFactory = new web3.eth.Contract(
        ABI,
        NFT_ADDRESS,
        
      )

      // Get Total NFT Amount
      const total = await p2uNFTFactory.methods._lastTokenID().call();
      console.log("Total Amount : ", total);
      setTotalNFT(total);

      const tokenIDs = await p2uNFTFactory.methods.tokensOfOwner(OWNER_ADDRESS).call();
      setTokenIDs(tokenIDs);
      setCurrentNFT(tokenIDs.length);
      const tokenURIs = await p2uNFTFactory.methods.tokenURIsOfOwner(OWNER_ADDRESS).call();
      for (let i = 0; i < tokenURIs.length; i++) {
        //------- Get JSON DATA
        let jsonData = await axios.get(convertURL(tokenURIs[i]));
        let data = jsonData.data;
        data.image = convertURL(`${data.image}`);
        data.id = i;
        data.tokenID = tokenIDs[i];
        allNFT.push(data);
        setFilterItems(allNFT);
      }

      setItems(allNFT);
    } catch (error) {
      myToast("Some error occured while loading data.", 'error'); 
    }
    
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    setLoading(true);
    await getCollections();
    await getAllNFTs();
    setLoading(false);
  }, [])

  return (
    <div className={cn("section-pt80", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.top}>
          {
            <div className={styles.title}>
              {filterItems.length} Items / Total {currentNFT}
            </div>
          }
          
          <form
            className={styles.search}
            onSubmit={handleSubmit}
            // onSubmit={(e) => handleSubmit(e)}
          >
            <input
              className={styles.input}
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              name="search"
              placeholder="Search ..."
              required
            />
            <button type="submit" className={styles.result} >
              <Icon name="search" size="16" />
            </button>
          </form>
        </div>
        <div className={styles.sorting}>
          {/* <div className={styles.dropdown}>
            <Dropdown
              className={styles.dropdown}
              value={date}
              setValue={setDate}
              options={dateOptions}
            />
          </div> */}
          <div className={styles.nav}>
            {navLinks.map((x, index) => (
              <button
                className={cn(styles.link, {
                  [styles.active]: index === activeIndex,
                })}
                onClick={() => filterByCollection(index)}
                key={index}
              >
                {x.name}
              </button>
            ))}
          </div>
        </div>
        <div className={styles.row}>
           <div className={styles.wrapper}>
            <div className={styles.list}>
              {filterItems.map((x, index) => (
                // <div className={styles.card} item={x}  key={index}> </div>
                <Card className={styles.card} item={x} collections={collections} tokenPrice={tokenPrice} key={index} />
              ))}
            </div>
            { loading && <div className={styles.btns}>
              <Loader className={styles.loader} color="white" />
              {/* <button className={cn("button-stroke", styles.button)}>
                <span>Load more</span>
              </button> */}
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapToStateProps = ({ uploading }) => ({
  upload: uploading.upload
});

const mapToDispatchProps = (dispatch) => ({
  uploadBid: (payload) => dispatch(getNFTDataAll(payload))
})

export default connect(mapToStateProps, mapToDispatchProps)(Search);
