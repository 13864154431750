import Web3 from "web3";
import { WEB3_PROVIDER } from "../../ABI/Pease2Ukraine/config";
const Web3Init = async () => {
    let web3;

    if (window.ethereum) {
        try {
            web3 = new Web3(window.ethereum);
        }
        catch (error) {
            console.log(error);
        }
    }
    else if (window.web3) {
        web3 = window.web3;
    }
    else {
        const provider = new Web3.providers.HttpProvider(
            WEB3_PROVIDER
        );
        web3 = new Web3(provider);
    }

    // web3 = new Web3(new Web3.providers.HttpProvider());
    // console.log("Connect Web3 with infura node successfully!")
    return web3;
}

export default Web3Init;