import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./collections.module.sass";
import Icon from "../../../components/Icon";
import Modal from "../../../components/Modal";
import CreateCollect from "../../../components/CreateCollect";
import { POLYGON_NET } from "../../../ABI/Pease2Ukraine/config";


const Collection = ({ className, items , selectedID,  onSelect}) => {

    const [mVisible, setMVisible] = useState(false);

    return (
        <div className={(className, styles.cards)}>
            {items.map((x, index) => (
                <div className={(index===selectedID) ?  styles.active + " " +  styles.card: styles.card } key={index} onClick={() => { onSelect(x) }}>
                    <div className={styles.plus} style={{ backgroundColor: x.color }}>
                    <img
                        className={styles.img}
                        width={"100%"}
                        height={150}
                        src={x.image}
                        alt="Collection"
                    />
                    </div>
                    <div className={styles.subtitle}>{x.name}</div>
                    <p className={styles.subDesc}>{x.description}</p>
                    <label className={styles.price}>{x.price} {POLYGON_NET.symbol}</label>
                </div>
            ))}
        </div>
    );
};

export default Collection;
