import React from "react";
import cn from "classnames";
import styles from "./Form.module.sass";
import Icon from "../Icon";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'

const Form = ({
  className,
  onSubmit,
  placeholder,
  email,
  setEmailValue,
  note,
  setNoteValue,
  type,
  name,
}) => {
  return (
    <div className={cn(styles.form, className)} >
      <input
        className={styles.input}
        type={type}
        value={email}
        onChange={(e) => setEmailValue(e.target.value)}
        name={name}
        placeholder={placeholder}
        required
      />
      <textarea className={styles.textarea} placeholder="Please enter your note..." required 
        value={note}
        rows={2}
        onChange={(e) => setNoteValue(e.target.value)}>
      </textarea>
      <button className={styles.btn} onClick={onSubmit}>
        {/* <Icon name="arrow-next" size="14" />
         */}
         <FontAwesomeIcon icon={faPaperPlane} className={styles.socialIcon}></FontAwesomeIcon>
        <label> SUBMIT</label>
      </button>
    </div>
  );
};

export default Form;
