import React,{ useRef, useEffect, useState } from "react";
import styles from "./roadmap.module.sass";
import { gsap } from "gsap";
import {
    Box,
    Button,
    Container,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
} from "@mui/material";
const RoadMap = () => {

    useEffect(() => {
        const tl = gsap.timeline({ repeat: -1, ease: "back.out(1)" });
        const t2 = gsap.timeline({
            repeat: -1,
            repeatDelay: 1,
            ease: "back.out(1)",
        });

        tl.set(".border-out", { rotate: 0 })
            .to(".border-out", {
                rotate: 60,
                duration: 1,
            })
            .to(".border-out", {
                rotate: 0,
                duration: 1,
            })
            .to(".border-out", {
                rotate: 360,
                duration: 3,
            })
            .to(".border-out", {
                rotate: 300,
                duration: 1,
            })
            .to(".border-out", {
                rotate: 360,
                duration: 1,
            })
            .to(".border-out", {
                rotate: 0,
                duration: 3,
            });

        t2.set(".border-in", { rotate: 0 })
            .to(".border-in", {
                rotate: -60,
                duration: 5,
            })
            .to(".border-in", {
                rotate: 0,
                duration: 5,
            })
            .to(".border-in", {
                rotate: -360,
                duration: 10,
            })
            .to(".border-in", {
                rotate: -300,
                duration: 5,
            })
            .to(".border-in", {
                rotate: -360,
                duration: 5,
            })
            .to(".border-in", {
                rotate: 0,
                duration: 15,
            });
    }, []);
 

    
  
  return (
    <>
    <div className = {styles.container}>
        <Box>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
            >
                <Box className="border-in">
                    <img
                        src="./images/border-circle-1.svg"
                        width={200}
                        height={200}
                        alt="Mark"
                    ></img>
                    
                </Box>
                <img src = "./images/fox1-small.png" width = {70} height = {70} className = {styles.mark}>
                </img>
                
            </Stack>

        </Box>
    </div>
    </>
  );
};

export default RoadMap;
