/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useMemo } from "react";
import DataListInput from "react-datalist-input";
import cn from "classnames";
import styles from "./UploadDetails.module.sass";
import Icon from "../../components/Icon";
import TextInput from "../../components/TextInput";
import Modal from "../../components/Modal";
import Preview from "./Preview";
import FolowSteps from "./FolowSteps";
import { connect } from "react-redux";
import { authSet } from "../../store/actions/auth.actions";
import {Rings} from  'react-loader-spinner'
import {NFT_ADDRESS, ABI, OWNER_ADDRESS, NFT_STORAGE_API_TOKEN} from "../../ABI/Pease2Ukraine/config.js";
import Web3Init from "../../components/InitWeb3";
import { NFTStorage } from "nft.storage"
import myToast from "../../components/Toast";
import axios from "axios";
import convertURL from "../../components/convertURL";


// Initialize the NFTStorage client
const storage = new NFTStorage({ token: NFT_STORAGE_API_TOKEN });


const Upload = (props) => {

  const [imgUpload, setImgUpload] = useState(false);
  const [load, setLoad] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [visiblePreview, setVisiblePreview] = useState(false);
  const [visibleCreateBtn, setVisibleCreateBtn] = useState(false);

  const [selectedImage, setSelectedImage] = useState();
  const [itemName, setItemName] = useState('');
  const [itemDesc, setItemDesc] = useState('');
  const [itemSource, setItemSource] = useState('');
  const [itemCollection, setItemCollection] = useState('');
  const [itemLongitude, setItemLongitude] = useState('');
  const [itemLatitude, setItemLatitude] = useState('');
  const [previewImage, setPreviewImage] = useState();
  const [imgUploadState, setImgUploadState] = useState(false);
  const [hashValue, setHashValue] = useState('');
  const [collectionItems, setCollectionItems] = useState([]);
  const [tokenPrices, setTokenPrices] = useState([]);
  const [tokenPrice, setTokenPrice] = useState(0.0);
  const [balance, setBalance] = useState(0);
  const [address, setAddress] = useState('');

  //======= Init Getting Data =========
  useEffect(async () => {
    setLoad(true);
    const web3 = await Web3Init();
    const networkId = await web3.eth.net.getId();
    // const deployedNetwork = Peace2UkraineConfig.networks[networkId.toString()];
    const p2uNFTFactory = new web3.eth.Contract(
      ABI,
      NFT_ADDRESS,
    );
  
    const prices =  await p2uNFTFactory.methods.getAllPrices().call();
    for (let index = 0; index < prices.length; index++) {
      tokenPrices.push(web3.utils.fromWei(prices[index].toString(), "ether"));
    }
    setTokenPrices(tokenPrices);

    const _collections = await p2uNFTFactory.methods.getAllCollections().call();

    const collectionItems = [];
    for (let i = 0; i < _collections.length; i++) {
      let jsonData = await axios.get(convertURL(_collections[i]));
      jsonData = jsonData.data;
      var itemData = {};
      itemData.id = i;
      itemData.image = convertURL(jsonData.image);
      itemData.name = jsonData.name;
      itemData.description = jsonData.description;
      
      collectionItems.push(itemData);
    }
    setCollectionItems(collectionItems);
    setLoad(false);

    
    if (window.ethereum != null ) {
      // if (window.ethereum != null && Object.keys(props.user).length) {
      await window.ethereum.request({ method: 'eth_requestAccounts' }).then(async (data) => {
        setAddress(data[0]);
        const web3 = await Web3Init();
        const balance = await web3.eth.getBalance(data[0]);
        setBalance(web3.utils.fromWei(balance.toString(), "ether"));
      })
    }
    else {
      setAddress('');
    }
  }, [])

  //====== Image Change ======  
  async function imageChange(e) {
    if (e.target.files && e.target.files.length > 0) {
      setImgUpload(true);

      //=== Load Image Cache ===
      const reader = new window.FileReader();
      reader.readAsArrayBuffer(e.target.files[0]);
      reader.onloadend = () => {
        // setSelectedImage(Buffer(reader.result));
        setPreviewImage(URL.createObjectURL(e.target.files[0]));
      }

      //== add ==
      const file = e.target.files[0];
      setSelectedImage(file);
      setVisibleCreateBtn(true)
      //====================  
      setImgUpload(false);

    }
  }

  const onSelect = useCallback(
    (selectedItem) => {
      setItemCollection(selectedItem.id);
      setTokenPrice(tokenPrices[selectedItem.id]);
    },
    [],
  );

  // Get Current Date
  const getToday = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    let todayStr =  yyyy + "-" + mm + '-' + dd;
    return todayStr;
  }

  //===== Use Demo ========
  const cItems = useMemo(() => collectionItems.map((oneItem) => ({
    label: oneItem.name,
    key: oneItem.id,
    someAdditionalValue: oneItem.value,
    ...oneItem,
  })), [collectionItems]);
  
  //===== Change Item Name ========
  const changeItemName = (e) => {
    setItemName(e.target.value);
  }

  const removeAll = () => {
    setPreviewImage();
    setSelectedImage();
    setItemName();
    setItemDesc();
    setVisibleCreateBtn(false);
  }

  const closeModal = () => {
    setVisibleModal(false);
  }


  //======== UPLOAD IMAGE & METADATA JSON ==========
  const createItem = async (e) => {
    if(itemCollection === '' || itemName === '' || itemDesc === '' ) {
      myToast("Please fill out  infomations to mint NFT", 'error'); return;
    };
    
    if(itemCollection === 0 && (itemLatitude === 0.0 || itemLatitude === '' ||  itemLongitude === '' ||  itemLongitude === 0.0)) {
      myToast("Please fill out  Location Data", 'error'); return;
    };

    setVisibleModal(true);

    setImgUploadState(false);
    const metadata = await storage.store({
      name: itemName,
      description: itemDesc,
      image: new File([selectedImage], `${itemName}.jpg`, { type: 'image/jpg' }),
      collection: itemCollection,
      attributes: {
        price: tokenPrice,
        mitDate: getToday(),
        position: {
          latitude: itemLatitude * 1,
          longitude: itemLongitude * 1,
        },
        NFTname: "PEACE2UKRAINE",
        source: itemSource
      }
    })

    console.log(metadata);
    setHashValue(metadata.url);
    setImgUploadState(true);

    e.preventDefault();
  }



  return (
    <>
      <div className={cn("section", styles.section)}>
        <div className={cn("container", styles.container)}>
          {
            (load) ? <div className={styles.loading}> <Rings color="#ffd166" height={80} width={80} /> LOADING...</div> :

            <div className={styles.setVal}>
              <div className={styles.wrapper}>
                <div className={styles.head}>
                  <div className={cn("h2", styles.title)}>
                    Create single NFT
                  </div>
                  {/* <button
                    className={cn("button-stroke button-small", styles.button)}
                  >
                    Switch to Multiple
                  </button> */}
                </div>
                <form className={styles.form} action="">
                  <div className={styles.options}>
                  
                    <div className={styles.category}>Choose collection</div>
                    <div className={styles.text}>
                      Choose an exiting collection
                    </div>
                    <DataListInput
                      placeholder={`Selecte Collection...`}
                      items={cItems}
                      onSelect={onSelect}
                      dropdownClassName={`${styles.collectDropdown}`}
                      inputClassName={`${styles.collect}`}
                    />
                  </div>
                  <div className={styles.list}>
                    <div className={styles.item}>
                      <div className={styles.category}>Upload file</div>
                      <div className={styles.note}>
                        Drag or choose your file to upload
                      </div>
                      <div className={styles.file}>
                        {
                        (imgUpload) ? 
                          <div>
                            <Rings color="#ffd166" height={80} width={80}  ariaLabel='Uploading'/>
                            Uploading...  
                          </div> :
                          <div>
                            <input onChange={imageChange} className={styles.load} type="file" />
                            <div className={styles.icon}>
                              <Icon name="upload-file" size="24" />
                            </div>
                            <div className={styles.format}>
                              PNG, GIF, WEBP, MP4 or MP3. Max 1Gb.
                            </div>
                            {
                              previewImage && (
                                <img src={(previewImage)} width="600" height="300" className={styles.imgFit} />
                              )
                            }
                          </div>
                        }
                      
                      </div>
                    </div>
                    <div className={styles.item}>
                      <div className={styles.category}>Item Details</div>
                      <div className={styles.fieldset}>
                        <TextInput
                          className={styles.field}
                          label="Item name"
                          name="Item"
                          type="text"
                          placeholder='e. g. Kiyev Beautiful Scene"'
                          onChange={changeItemName}
                          required
                        />
                        <TextInput
                          className={styles.field}
                          label="Description"
                          name="Description"
                          type="text"
                          placeholder="e. g. “After purchasing you will able to recived the beautiful scene photo...”"
                          value={itemDesc}
                          onChange={(e) => setItemDesc(e.target.value)}
                          required
                        />

                        <TextInput
                          className={styles.field}
                          label="Source"
                          name="Source"
                          type="text"
                          placeholder="e. g. “By Art Nostalgy - Own work, CC BY-SA 4.0, https://wwww....”"
                          value={itemSource}
                          onChange={(e) => setItemSource(e.target.value)}
                          required
                        />
                        
                        
                      </div>
                      {
                       (itemCollection === 0) ? 
                       <>
                        <br/>
                        <div className={styles.category}>Location Properties</div>
                        <div className={styles.fieldset}>
                          <div className={styles.row}>
                              <div className={styles.col}>
                                <TextInput
                                  className={styles.field}
                                  label="Latitude"
                                  name="latitude"
                                  type="number"
                                  placeholder="e.g 56.34"
                                  onChange={(e) => setItemLatitude(e.target.value)}
                                  required
                                />
                              </div>
                              <div className={styles.col}>
                                <TextInput
                                  className={styles.field}
                                  label="Longitude"
                                  name="longitude"
                                  type="number"
                                  placeholder="e.g 24.50"
                                  onChange={(e) => setItemLongitude(e.target.value)}
                                  required
                                />
                              </div>
                            </div>
                        </div>
                      
                       </> : null
                       }
                      </div>
                  </div>
                  
                  <div className={styles.foot}>
                    <button
                      className={cn("button-stroke tablet-show", styles.button)}
                      onClick={() => setVisiblePreview(true)}
                      type="button"
                    >
                      Preview
                    </button>
                    <button
                      className={cn(`button ${visibleCreateBtn ? '' : "disabled"}`, styles.button)}
                      onClick={createItem}
                      type="button"
                    >
                      <span>Create</span>
                      <Icon name="arrow-next" size="10" />
                    </button>
                  </div>
                </form>
              </div>
              <Preview
                className={cn(styles.preview, { [styles.active]: visiblePreview })}
                onClose={() => setVisiblePreview(false)}
                imageData={previewImage}
                itemName={itemName}
                removeAll={removeAll}
                itemDesc={itemDesc}
                itemSource={itemSource}
                tokenPrice = {tokenPrice}
                accountBalance = {balance}
              />
            </div>
          
          }
        </div>
      </div>

      <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <FolowSteps
          className={styles.steps}
          uploadState={imgUploadState}
          hashValue={hashValue}
          itemName={itemName}
          itemDesc={itemDesc}
          itemCollection={itemCollection}
          closeModal={closeModal}
        />
      </Modal>
    </>
  );
};

const mapToStateProps = ({ auth }) => ({
  user: auth.user
});

const mapToDispatchProps = (dispatch) => ({
  authSet: (payload) => dispatch(authSet(payload))
})

export default connect(mapToStateProps, mapToDispatchProps)(Upload);
