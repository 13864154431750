import React, { useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Footer.module.sass";
import Group from "./Group";
import Image from "../Image";
import Form from "../ContactForm";
import Theme from "../Theme";
import Icon from "../Icon";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import emailjs from '@emailjs/browser';
import myToast from "../Toast";

const items = [
  {
    title: "Users",
    menu: [
      {
        title: "Buy NFT",
        url: "/search01",
      },
      {
        title: "Connect wallet",
        url: "/connect-wallet",
      },
      {
        title: "My Items",
        url: "/myItemView",
      },
      {
        title: "FAQ",
        url: "/faq",
      },
    ],
  },
  // {
  //   title: "Admin",
  //   menu: [
  //     {
  //       title: "Create Collection",
  //       url: "/createCollection",
  //     },
  //     {
  //       title: "Create item",
  //       url: "/upload-details",
  //     },
  //     {
  //       title: "Set Price",
  //       url: "/setPrice",
  //     },
  //     {
  //       title: "Withdraw",
  //       url: "/withdraw",
  //     },
  //   ],
  // },
];

const Footers = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);

  const handleSubmit = () => {
    setSending(true);
    const templateParams = {
      from_name: email,
      to_name: "Support Team for Peace2Ukraine",
      message: message,
    };
    
    emailjs.send("service_tznutws","template_5p751tq", templateParams, 'tZodxF4-YkMwJWCtD')
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      myToast("Submitted succressfully!","success");
      setSending(false);

    }, (err) => {
      console.log('FAILED...', err);
      myToast(`SUBMIT FAILED! \r\n${err.text}`,"error");
      setSending(false);


    });
  };

  return (
    <footer className={styles.footer}>
      <div className={cn("container", styles.container)}>
        <div className={styles.row}>
          <div className={styles.col}>
            <Link className={styles.logo} to="/">
              <Image
                className={styles.pic}
                src="/images/fox1-250px.png"
                srcDark="/images/fox1-250px.png"
                alt="BlockFox"
              />
            </Link>
            <div className={styles.info}>Peace <strong>2</strong> Ukraine</div>
            <div className={styles.version}>
            </div>
          </div>
          <div className={styles.col}>
            {items.map((x, index) => (
              
              <Group className={styles.group} item={x} key={index} />
            ))}
          </div>
          <div className={styles.col}>
            <div className={styles.category}>
              <label> Contact Us</label>
              <div className={styles.text}>
                {/* Twitter */}
                <a
                  className={cn("", styles.social)}
                  href="https://twitter.com/peace2ukra1ne" target="_blank"
                ><Icon name="twitter" size="20" className={styles.socialIcon} /></a>

                {/* LinkedIn */}
                <a
                  className={cn("", styles.social)}
                  href="mailto:fullsolution2020@gmail.com" target="_blank"
                ><FontAwesomeIcon icon={faEnvelope} className={styles.socialIcon}></FontAwesomeIcon></a>
              </div>
            
            </div>
            
             <Form
              className={styles.form}
              email={email}
              setEmailValue={setEmail}
              note={message}
              setNoteValue={setMessage}
              onSubmit={() => handleSubmit()}
              placeholder="Enter your email"
              type="email"
              name="email"
            />
          </div>
        </div>
        <div className={styles.foot}>
          <div className={styles.copyright}>
            Copyright © 2022. All rights reserved
          </div>
          <div className={styles.note}>
            We use cookies for better service. <a href="/#">Accept</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footers;
