import styles from "./Videos.module.sass";
import YouTube from 'react-youtube';
const Videos = () => {
  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };
  const _onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }
  return (
    <>
      <div className={styles.container}>
        <YouTube videoId="Pzd56Q1Mnr4" opts={opts} onReady={_onReady} className={styles.video} />
      </div>
    </>
  );
};

export default Videos;
