/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";
import styles from "./Header.module.sass";
// import Icon from "../Icon";
import Image from "../Image";
// import Notification from "./Notification";
import User from "./User";
import { connect } from "react-redux";
import { authLogout } from "../../store/actions/auth.actions";
import { OWNER_ADDRESS } from "../../ABI/Pease2Ukraine/config";

const nav = [
  {
    url: "/search01",
    title: "NFT Gallery",
  }
];

const adminNav = [
  {
    url: "/upload-details",
    title: "Mint",
  },
  {
    url: "/createCollection",
    title: "Collections",
  },
  {
    url: "/setPrice",
    title: "Price",
  },
]


const Headers = (props) => {
  const [visibleNav, setVisibleNav] = useState(false);
  // const [search, setSearch] = useState("");
  const [user, setUser] = useState({});
  const connectFlag = sessionStorage.getItem("connect");
  const [address, setAddress] = useState("");

  useEffect(async () => {

    if (connectFlag !== null) {
      await window.ethereum.request({ method: 'eth_requestAccounts' }).then(async (data) => {
        setUser(data[0]);
        setAddress(data[0]);
      });
      if (window.ethereum) {
        window.ethereum.on('accountsChanged', function (accounts) {
          if (accounts) {
            window.location.reload();
          }
          else {
            window.location.reload();
          }
        });
        window.ethereum.on('networkChanged', function (_neworkId) {
          window.location.reload();
        });
      }
    }

  }, [props])

  // const handleSubmit = (e) => {
  //   alert();
  // };

  return (
    <header className={styles.header}>
      <div className={cn("container", styles.container)}>
        <Link className={styles.logo} to="/">
          <Image
            className={styles.pic}
            src="/images/fox1-small.png"
            srcDark="/images/fox1-small.png"
            alt="BlockFox"
          />
          
        </Link>
        <Link className={styles.logoName} to="/">
            Peace 2 Ukraine    
        </Link>
        <div className={cn(styles.wrapper, { [styles.active]: visibleNav })}>
          
          <nav className={styles.nav}>
            {nav.map((x, index) => (
              <Link
                className={styles.link}
                // activeClassName={styles.active}
                to={x.url}
                key={index}
              >
                {x.title}
              </Link>
            ))}
            { (address.toLowerCase() === OWNER_ADDRESS.toLowerCase()) && 
              adminNav.map((x, index) => (
                <Link
                  className={styles.link}
                  // activeClassName={styles.active}
                  to={x.url}
                  key={index}
                >
                  {x.title}
                </Link>
              )) 
            }
            {!Object.keys(user).length ? connectFlag !== "1" ?
              <p className = {styles.textStrong}>NFTs for Ukraine Charity</p>
              : null : <p></p>
            }
            
            {!Object.keys(user).length ? connectFlag !== "1" ?
              <Link
                className={cn("button-stroke-yellow button-small", styles.button)}
                to="/connect-wallet"
              >
                <p className ={styles.walletbtn}>Connect Wallet</p>
              </Link>
              : null : <p></p>
            }
          </nav>
          
          {/* <form
            className={styles.search}
            action=""
            onSubmit={() => handleSubmit()}
          >
            <input
              className={styles.input}
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              name="search"
              placeholder="Search"
              required
            />
            <button className={styles.result}>
              <Icon name="search" size="20" />
            </button>
          </form> */}
          {/* <Link
            className={cn("button-small", styles.button)}
            to="/upload-variants"
          >
            Upload
          </Link> */}
        </div>
        {/* <Notification className={styles.notification} /> */}
        {/* <Link
          className={cn("button-small", styles.button)}
          to="/upload-variants"
        >
          Upload
        </Link> */}
        {!Object.keys(user).length ? connectFlag !== "1" ?
          <Link
            className={cn("button-stroke-yellow button-small", styles.button)}
            to="/connect-wallet"
          >
            Connect Wallet
          </Link>
          : null : <User className={styles.user} userItem={user} logout={() => props.authLogout()} />
        }
        <button
          className={cn(styles.burger, { [styles.active]: visibleNav })}
          onClick={() => setVisibleNav(!visibleNav)}
        ></button>
      </div>
    </header>
  );
};

const mapToStateProps = ({ auth }) => ({
  user: auth.user
})

const mapToDispatchProps = dispatch => ({
  authLogout: () => dispatch(authLogout())
})
export default connect(mapToStateProps, mapToDispatchProps)(Headers);
