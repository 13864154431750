/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Price.module.sass";
import Icon from "../../components/Icon";
import TextInput from "../../components/TextInput";
import Modal from "../../components/Modal";
import FolowSteps from "./FolowSteps";
import { connect } from "react-redux";
import { authSet } from "../../store/actions/auth.actions";
import {Rings} from  'react-loader-spinner'
import {ABI,NFT_ADDRESS,NFT_STORAGE_API_TOKEN} from "../../ABI/Pease2Ukraine/config.js";
import Web3Init from "../../components/InitWeb3";
import myToast from "../../components/Toast";
import axios from "axios";
import convertURL from "../../components/convertURL";
import Collections from "./Collections";

const MIN_PRICE = 0.01;

const Upload = (props) => {

  const [load, setLoad] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleCreateBtn, setVisibleCreateBtn] = useState(false);
  const [collectionItems, setCollectionItems] = useState([]);
  const [newPrice, changePrice] = useState('');
  const [tokenPrice, setTokenPrice] = useState(0.0);
  const [balance, setBalance] = useState(0);
  const [address, setAddress] = useState('');
  const [selectedID, setSelectedID] = useState(0);
  const [selectedCollection, setSelectedCollection] = useState({});

  //======= Init Getting Data =========
  useEffect(async () => {
    
    setLoad(true);
    const web3 = await Web3Init();
    const networkId = await web3.eth.net.getId();
    //console.log("NetWorkID",  networkId);
    // const deployedNetwork = Peace2UkraineConfig.networks[networkId.toString()];
    const p2uNFTFactory = new web3.eth.Contract(
      ABI,
      NFT_ADDRESS,
    );
  
    const prices =  await p2uNFTFactory.methods.getAllPrices().call();
    // setTokenPrice(web3.utils.fromWei(price.toString(), "ether"));

    const _collections = await p2uNFTFactory.methods.getAllCollections().call();
    //console.log("Collections", _collections);

    const collectionItems = [];
    for (let i = 0; i < _collections.length; i++) {
      let jsonData = await axios.get(convertURL(_collections[i]));
      jsonData = jsonData.data;
      var itemData = {};
      itemData.id = i;
      itemData.price = (i < prices.length) ? web3.utils.fromWei( prices[i].toString(), "ether") : 0.0;
      itemData.image = convertURL(jsonData.image);
      itemData.name = jsonData.name;
      itemData.description = jsonData.description;
      
      collectionItems.push(itemData);
    }
    setCollectionItems(collectionItems);
    setSelectedCollection(collectionItems[0]);

    if (window.ethereum != null ) {
      // if (window.ethereum != null && Object.keys(props.user).length) {
      await window.ethereum.request({ method: 'eth_requestAccounts' }).then(async (data) => {
        setAddress(data[0]);
        const web3 = await Web3Init();
        const balance = await web3.eth.getBalance(data[0]);
        setBalance(web3.utils.fromWei(balance.toString(), "ether"));
      })
    }
    else {
      setAddress('');
      //console.log("address else part=============================")
    }
    setLoad(false);

  }, [])
  

  //  Update Price
  const update = ()=> {
    //console.log("======Update ==========");
    if(newPrice === ""){
      myToast("Please enter new price!", "error");
      return;
    } 
    if(newPrice < MIN_PRICE) {
      myToast (`Price should be bigger than ${MIN_PRICE} MATIC`, 'warning');
      return;
    }
    setVisibleModal(true);
  }

  const closeModal = () => {
    setVisibleModal(false);
  }


  const changeCollection = (index) => {
    //console.log(index);
    setSelectedID(index.id);
    setSelectedCollection(index);
  }
  return (
    <>
      <div className={cn("section", styles.section)}>
        <div className={cn("container", styles.container)}>
          {
            (load) ? <div className={styles.loading}> <Rings color="#ffd166" height={80} width={80} /> LOADING...</div> :
            <div style={{width: "100%", display: "flex", flexDirection: "column"}}>
              <Collections items = {collectionItems} selectedID={selectedID} onSelect={(index) => {changeCollection(index);}} />
              <div className={styles.setVal}>

                <div className={styles.wrapper}>
                  <div className={styles.head}>
                    <div className={cn("h2", styles.title)}>
                      Set NFT Price
                    </div>
                  </div>
                  <form className={styles.form} action="">
                    <div className={styles.list}>
                      
                      <div className={styles.item}>
                        <div className={styles.category}></div>
                          <h3>Collection Name: <label className={styles.info}>{selectedCollection.name}</label></h3>
                          <h3>Description : <label className={styles.info}>{selectedCollection.description}</label></h3>
                          <h3>NFT Price: <label className={styles.info}>{selectedCollection.price} <strong className={styles.matic}>MATIC</strong></label></h3>
                          <div className={styles.fieldset}>
                            <TextInput
                              className={styles.field}
                              label="New Price"
                              name="price"
                              type="number"
                              steps="0.01"
                              placeholder='e. g. 100 MATIC'
                              onChange={(e) => changePrice(e.target.value)}
                              required
                            />
                          </div>
                          
                        </div>
                    </div>
                    
                    <div className={styles.foot}>
                    
                      <button
                        className={cn(`button`, styles.button)}
                        onClick={update}
                        type="button"
                      >
                        <span>UPDATE</span>
                        <Icon name="arrow-next" size="10" />
                      </button>
                    </div>
                  </form>
                </div>
                
              </div>
          
            </div>
          }
        </div>
      </div>

      <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <FolowSteps
          className={styles.steps}
          newPrice={newPrice}
          collectionID = {selectedID}
          closeModal={closeModal}
        />
      </Modal>
    </>
  );
};

const mapToStateProps = ({ auth }) => ({
  user: auth.user
});

const mapToDispatchProps = (dispatch) => ({
  authSet: (payload) => dispatch(authSet(payload))
})

export default connect(mapToStateProps, mapToDispatchProps)(Upload);
