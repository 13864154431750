import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./collections.module.sass";
import Icon from "../../../components/Icon";
import Modal from "../../../components/Modal";
import CreateCollect from "../../../components/CreateCollect";

const Collection = ({ className, items }) => {

    const [mVisible, setMVisible] = useState(false);

    return (
        <div className={(className, styles.cards)}>
            {items.map((x, index) => (
                <div className={styles.card} key={index} onClick={() => { setMVisible(true) }}>
                    <div className={styles.plus} style={{ backgroundColor: x.color }}>
                    <img
                        className={styles.img}
                        width={"100%"}
                        height={150}
                        src={x.image}
                        alt="Collection"
                    />
                    </div>
                    <div className={styles.subtitle}>{x.name}</div>
                    <p className={styles.subDesc}>{x.description}</p>
                </div>
            ))}
        </div>
    );
};

export default Collection;
