import Web3 from "web3";
import {POLYGON_NET}  from "../ABI/Pease2Ukraine/config"


const connectPolygon = async () => {
    console.log("Polygon testnet: ", POLYGON_NET);
    // debugger;
    try {
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: POLYGON_NET.chainID }], // Hexadecimal version of 80001, prefixed with 0x
        });
        return true;
    } catch (error) {
        if (error.code === 4902) {
            try {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [{ 
                        chainId: POLYGON_NET.chainID, // Hexadecimal version of 80001, prefixed with 0x
                        chainName: POLYGON_NET.networkName,
                        nativeCurrency: {
                            name: POLYGON_NET.symbol,
                            symbol: POLYGON_NET.symbol,
                            decimals: 18,
                        },
                        rpcUrls: [POLYGON_NET.rpc],
                        blockExplorerUrls: [POLYGON_NET.blockURL],
                        iconUrls: [""],
                
                    }],
                });
                return true;
            } catch (addError){
                console.log('Did not add network');
                return false;
            }
        }
    }
}
export default connectPolygon;