import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import store from "./store";
import {Provider} from "react-redux";
import 'mapbox-gl/dist/mapbox-gl.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
ReactDOM.render(
  <React.Fragment>
    <Provider store={store}>
        <App />
        <ToastContainer
          theme = "colored" 
        />
    </Provider>
  </React.Fragment>,
  document.getElementById("root")
);
