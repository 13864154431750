import React,{ useRef, useEffect, useState } from "react";
import styles from "./valences.module.sass";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink,faWallet } from '@fortawesome/free-solid-svg-icons';
import Icon from "../../../components/Icon";
import cn from "classnames";
const Valences = () => {

  
 

    
  
  return (
    <>
      <div className={styles.container}>
        <div className={styles.desc}>
            <p className={styles.textStrong}>
            Show your support to the people of Ukraine by purchasing one of these commemorative NFTs. 
            </p>

            <p className={styles.text}>
            Each one represents a location in beautiful Ukraine, including a scene from the city and its coat of arms. 
            </p>

            <p className={styles.text}>
            We don’t know if these buildings will be standing in the future, so please preserve them virtually! 
            </p>
            <p className = {styles.textVal}>80% of all payment 
            <Link
                to="https://unitedhelpukraine.org/donate"
                className={styles.link}
              > goes to charity</Link>, 20% will help to support us and our families in Ukraine</p>
 
          
              <Link
                to="https://unitedhelpukraine.org/donate"
                className={styles.link}
              ><FontAwesomeIcon icon={faLink}></FontAwesomeIcon> https://unitedhelpukraine.org/donate
              </Link>
              <Link to="https://polygonscan.com/address/0xf4A9d47F9322595d60B23B55d558C3d996b25510" className={styles.link}>
                <FontAwesomeIcon icon={faWallet}></FontAwesomeIcon> 0xf4A9d47F9322595d60B23B55d558C3d996b25510
              </Link>
              <Link
                className={cn("button-stroke-yellow button-small", styles.button)}
                to="/search01"
                ><Icon name="coin" size="20" />Buy NFT</Link>
        </div>
      </div>
    </>
  );
};

export default Valences;
